<template>
  <v-app id="v-app" fill-height>
      <v-app-bar app>        
        <v-app-bar-title>
          <a
            href="https://compolytics.com/cicada/"
            target="about:_blank"
            >
            <v-img
              aspect-ratio="1"
              width="1em"
              src="./assets/compolytics_logo.svg"
              cover
            ></v-img>
          </a>
        </v-app-bar-title>
        <v-app-bar-title>
          CICADA
        </v-app-bar-title>
        <v-btn v-if="loggedIn">
          <v-icon>
            mdi-account-circle
          </v-icon>
          <v-menu 
            activator="parent"
            anchor="bottom end">
            <v-list>
              <v-list-item>
                <v-btn flat @click="logout"> 
                  Logout
                  <v-icon class="ms-2">
                    mdi-logout
                  </v-icon>
                </v-btn>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-btn>        
      </v-app-bar>

      <v-main>
        <v-container fluid fill-height>
          <router-view></router-view>
        </v-container>
      </v-main>
      
      <v-bottom-navigation
        v-if="loggedIn"
        dark
        shift
        >
        <v-btn :to="{name:'Device'}">
          <span>Device</span>
          <v-icon v-if="deviceConnected">mdi-bluetooth-connect</v-icon>
          <v-icon v-else>mdi-bluetooth</v-icon>
        </v-btn>
        <v-btn v-if="deviceConnected" :to="{name:'Scan'}">
          <span>Scan</span>
          <v-icon>mdi-chart-box-plus-outline</v-icon>
        </v-btn>

        <v-btn :to="{name:'Library'}">
          <span>Browse</span>
          <v-icon>mdi-database</v-icon>
        </v-btn>

        <v-btn :to="{name:'Credits'}">
          <span>About</span>
          <v-icon>mdi-image</v-icon>
        </v-btn>
      </v-bottom-navigation>
  </v-app>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
  data: () => ({
  }),
  computed: {
    ...mapGetters({
      loggedIn: 'auth/isAuthenticated',
      deviceConnected: 'scancorder/isConnected'
    })
  },
  created() {
    this.registerServiceWorker();
    this.$store.dispatch( 'config/disableFakeMode' );
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/logout");
      this.$router.push( { name: "Login" } );
    },
    async registerServiceWorker() {
      console.log('Trying to register ServiceWorker.');
      if ( ! ('serviceWorker' in navigator ) ) {
        console.warn('ServiceWorker not supported.');
        return;
      }

      try {
        const registration = await navigator.serviceWorker.register(
          '/sw.js', {
            scope: '/',
          }
        );
        if (registration.installing) {
          console.log('Service worker installing');
        } else if (registration.waiting) {
          console.log('Service worker installed');
        } else if (registration.active) {
          console.log('Service worker active');
        }
      } catch (error) {
        console.error(`Registration failed with ${error}`);
      }
    }

  }

}
</script>

<style>
#v-app {
  background: rgba(0,0,0,0);
}
body {
  background-image: url('assets/heaxgon_bg.svg');
}

</style>
