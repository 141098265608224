
var ScancorderConfig = {
    uuids: {
        services: {
            device: {
                uuid: 'a036d97c-3e15-4e6c-85af-9bd7627cd9b8',
                characteristics: {
                    deviceName: '39ff4e8c-87f4-4c81-bcd7-d86a03338e1f',
                    firmwareVersion: '5f031591-3ddd-4013-a859-fbd21f7bc077',
                    hardwareVersion: '8b17065e-cac2-40b4-bd99-9e4ca6a59685',
                }
            },
            power: {
                uuid: '0000180f-0000-1000-8000-00805f9b34fb',
                characteristics: {
                    batteryLevel: '00002a19-0000-1000-8000-00805f9b34fb',
                    batteryVoltage: 'daef0566-6dfa-433f-b697-92f4aabe4ef4',
                    usbVoltage: 'c8336f9b-412e-41ae-9ed9-2b88998bdbf9',
                }
            },      
            sensorhead: {
                uuid: '042a81c6-92e7-4bbd-b1aa-d06eaafcf337',
                characteristics: {
                    connected: '3d343df0-c1fc-4c81-9a47-286c6e079a28',
                    sensortype: 'dbb5cb20-f46a-4c52-9f2f-a754d5b7b6ed',
                    sensorSerial: '5af1da71-166a-4be7-a6a5-edd3077ccd8a',
                    sensorConfiguration: '9eea4959-ee8d-45cc-8af1-46f62c71f64d',
                    sensorStatistics: '8fc9d974-a253-444d-a2f5-f8892e60f721',
                    measurementData: 'b74506cb-9b0b-4245-a1bc-7ee3dfb046ae',
                    measurementControl: 'ddae3746-63ef-4f97-a4a0-ebe241bbab31',
                    exposureTime: 'b524c744-afcf-4a85-ae76-a9f19915695a',
                    gain: '07df4055-a985-4bfd-a470-8426cf5d19b4',
                    ledCurrent: 'da40175c-e1dc-46a3-bc79-0ae2b2f8f40a',
                    additionalInfo: '5b652fcf-1c68-4065-889a-0a66b61f1eb2',
                }
            },
            otaProgrammer: {
                uuid: 'f959feb2-a75d-4927-9429-18432eebc795',
                characteristics: {
                    error: 'cb9de500-f0d3-445c-a0b6-7e0dad79a99b',
                    firmwareInfo: '0ea9d32a-82d1-44e1-965f-24e1cada1b93',
                    firmwareUpload: '90747c62-d67f-438a-8180-cbb840e1e362',
                }
            }   
        },
    }
}
ScancorderConfig.uuids.allServices = [
    ScancorderConfig.uuids.services.device.uuid,
    ScancorderConfig.uuids.services.power.uuid,
    ScancorderConfig.uuids.services.sensorhead.uuid,
    ScancorderConfig.uuids.services.otaProgrammer.uuid,
]
ScancorderConfig.bleRequestOptions = {
    filters: [
        {
            namePrefix: "Comp",
            //services: ScancorderConfig.uuids.allServices,
        }
    ],
    optionalServices: ScancorderConfig.uuids.allServices,
}
console.dir( ScancorderConfig )
export default ScancorderConfig;