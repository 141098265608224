<template>
    <div id="MeasurementDataMatrix">
        <div 
            style="display:flex;justify-content:center;"
            v-if="values && values.length > 0"
            >
            <v-slider
                v-model="maxValuePower"
                min="0"
                max="16"
                step="1"
                :style="{maxWidth: '400px' }"
            >
                <template v-slot:append>
                    <span class="ms-2" > 
                        2 <sup>{{ maxValuePower }}</sup> 
                    </span>
                </template>
            </v-slider>   
        </div>
        <div 
            id="dataMatrixContainer"
            >
            <table style="border-spacing=2px;">
                <thead >
                    <tr>
                        <th
                            />
                        <th
                            />
                        <th 
                            :colspan="values[0].length"
                            class="text-center"
                            >
                            Sensor Channel Wavelength [nm]
                        </th>
                    </tr>
                    <tr>
                        <th/>
                        <th/>
                        <th
                            v-for="label in columnLabels"
                            v-bind:key="label + Math.random().toString(16).slice(4)"
                            class="text-center"
                            scope="col"
                            >
                            {{ label }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(data, index) in values" v-bind:key="data"
                        >
                        <th 
                            v-if="index==0"
                            scope="row"
                            :rowspan="values.length"
                            class="text-center p-0 vertical"
                            >
                            Led peak wavelength [nm]
                        </th>
                        <th 
                            class="pr-3"
                            scope="row"
                            >
                            {{ ( rowLabels && rowLabels.length > index ) ? rowLabels[index] : "" }}
                        </th>
                        <td 
                            v-for="value in data" 
                            v-bind:key="value" 
                            :style="{ 
                                backgroundColor: getColor(index, value ),
                            }"
                            :class="(value >= 65535) ? 'saturation' : '' ">
                            <div class="measurement-matrix-entry"    
                                >
                                {{ numeralFormat( value, formatString ) }}
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>            
        </div>
    </div>
</template>

<script>
import interpolate from 'color-interpolate';
import { mapGetters } from 'vuex';

export default {
    props: [
        'colors',
        'values',
        'rowLabels',
        'columnLabels'
    ],
    data() {
        return {
            maxValuePower: 12,
            formatString: '0',
        }
    },
    computed: {
        ...mapGetters( {
            whitebalance: 'config/whiteBalance',
        } ),
        maxValue() {
            return Math.pow( 2, this.maxValuePower)
        },
    },
    watch: {
        values() {
            console.log("here:", this.whitebalance );
            if ( ! this.whitebalance )
                this.formatString = '0';
            else
                this.formatString = '0.000';
        }
    },
    mounted() {        
        if ( ! this.whitebalance )
            this.formatString = '0';
        else {
            this.formatString = '0.000';
            this.maxValuePower = 0;
        }
    },
    methods: {
        getColor( colorIndex, value ) {
            let targetColor = ( this.colors && this.colors.length > colorIndex ) ? this.colors[ colorIndex ] : 'black';
            let colormap = interpolate( [ '#ffffff',  targetColor ] );
            return colormap( value / this.maxValue );
        }
    }
}
</script>

<style>
#MeasurementDataMatrix {
    overflow: hidden;
}
#dataMatrixContainer {
    margin-bottom: 1em;
    overflow-x: scroll;
}
#dataMatrixContainer table {
    margin-left: auto;
    margin-right: auto;
}

th.vertical {
    writing-mode: vertical-lr;
    padding:0px
}

td {
    border-radius: 10px;
    aspect-ratio: 1;
    height: 3.3em;
}
.saturation {
    border-color: #821a1a !important;
    border-top-style: solid !important;
    border-right-style: solid !important;
    border-bottom-style: solid !important;
    border-left-style: solid !important;
    border-top-width: thick !important;
    border-right-width: thick !important;
    border-bottom-width: thick !important;
    border-left-width: thick !important;
}
.measurement-matrix-entry {
    display: flex;
    align-items: center;
    justify-content: center;
    aspect-ratio: 1 / 1;
    color:rgb(177, 177, 177);
    mix-blend-mode: difference;
    height: 100%;
    overflow: hidden;
}
</style>