<template>
    <v-tooltip
        anchor="end"
        >
        <template v-slot:activator="{ props }">
            <v-btn
                v-if="mismatch"
                id="whitebalanceMismatchButton"
                color="#c4ac3d"
                icon="mdi-alert-circle"
                elevation="20"
                style=""
                v-bind="props"
                >
                Button
            </v-btn>
        </template>
        <span>Whitebalance Mismatch</span>
    </v-tooltip>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    props: [
        'values',
    ],
    computed: {
        ...mapGetters( {
          deviceInfo: 'scancorder/deviceInfo',
          whiteBalance: 'config/whiteBalance',
          fakeMode: 'config/fakeMode',
        } ),
    },
    data() {
        return {
            mismatch: false,
        }
    },
    watch: {
        values() {
            this.checkeWhitebalanceMatch().then( ( mismatch ) => {
                this.mismatch = mismatch;
            } )
        }
    },
    methods: {        
        ...mapActions( {
            readMeasurementSettings: 'scancorder/readMeasurementSettings',
        } ),
        async checkeWhitebalanceMatch() {
            let settings = await this.readMeasurementSettings( this.fakeMode );
            console.log( settings );
            let whiteBalance = this.whiteBalance;
            console.log( whiteBalance );
            // Check Compatibility

            // No settings, no mismatch
            if ( ! settings ) 
                return false;
            
            // No whitebalance at all, so there is no mismatch
            if ( ! whiteBalance )
                return false;
            
            // There is a whitebalance, but it has no settings.
            // It is a mismatch.
            if ( ! whiteBalance.settings )
                return false;

            // Compare each of the settings individually
            if ( whiteBalance.settings.exposuretime != settings.exposuretime )
                return true;
            if ( whiteBalance.settings.gain != settings.gain )
                return true;

            // Compare dimensions
            if ( whiteBalance.values.length != this.values.length )
                return true;
            if ( whiteBalance.values[0].length != this.values[0].length )
                return true;

            // In theory we could also compre the device that was used to capture the
            // white balance to be the same as the one the measurment is done with now.

            return false;
        }
    }
}
</script>

<style>

#whitebalanceMismatchButton {
  position: fixed;
  left: 96px;
  top: 82px;
  z-index: 2001;
}
</style>