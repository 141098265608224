// Auth store
/* eslint-disable */
// initial state


import ScancorderConfig from '@/config/Scancorder';
import BleUtils from '@/utils/BleUtils';


function defaultDeviceInfo() {
  return {
    name:"",
    firmwareVersion: "0",
    hardwareVersion: "0",
    type:"unknown",
    serial: null,
    sensorHead: {
      serial: null,
      type: 0,
      name: "",
      sensor: 0,
      driver: 0,
      statistiscs: {
        boot:0,
        measurements:0,
        writes:0,
        length:0,
      },
      additionalInfo: {},
    },
    updated: false,
  }
}

const state = () => ( {
    device: null,
    server: null,
    deviceInfo: defaultDeviceInfo(),
    services: new Map(),
    characteristics: new Map(),
  } )
  
  // getters
  const getters = {
    isConnected: (state, getters, rootState) => {
      return ( state.device != null ) && 
        ( state.server != null ) && 
        ( state.server.connected ) && 
        ( state.deviceInfo.updated );
    },
    device: (state, getters, rootState) => {
      return state.device;
    },
    deviceInfo: (state, getters, rootState) => {
      return state.deviceInfo;
    },
    hasDriver: (state, getters, rootState) => {
      return state.deviceInfo.sensorHead.driver != 0;
    }
  }
  
  // actions
  const actions = {
    // Gets all devices that are already paired
    async getDevices() {      
      let devices = await navigator.bluetooth.getDevices();
      console.dir( devices );
      return devices;
    },
    // Removes a ble device from the list
    async removeDevice( context, device ) {
      device.forget();
      return context.dispatch( 'getDevices' );
    },
    // Starts the connect routine.
    // When a device is specified, the system will
    // try to connect to it, if null is provided
    // the ble selection dialog is shown.
    async connect( { dispatch, commit, state }, device ) {
      try {
        if ( device == null ) {
          console.log('Connecting...');
          console.dir( ScancorderConfig.bleRequestOptions );
          device = await navigator.bluetooth.requestDevice( ScancorderConfig.bleRequestOptions );
        }
        commit( 'setDevice', device );
        console.dir( device );
        
        // Connect to the device gatt server 
        let server = await device.gatt.connect();
        commit( 'setServer', server );
        console.dir( server );

        // Query device information
        let deviceInfo = await dispatch('readBasicDeviceInfo');
        deviceInfo.sensorHead = await dispatch('readSensorHeadInfo');
        deviceInfo.updated = true;
        commit( 'setDeviceInfo', deviceInfo );
        return Promise.resolve();
      } catch ( error ) {
        console.error( "Can not connect to BLE device server" );
        console.error( error );
        commit( 'setServer', null );
        commit( 'setDevice', null );
        return Promise.reject( error );
      }
    },
    async readBasicDeviceInfo( { dispatch }, state ) {
      let deviceInfo = defaultDeviceInfo();
      let bleValue = await dispatch('queryValue', {
        serviceUuid: ScancorderConfig.uuids.services.device.uuid,
        characteristicUuid: ScancorderConfig.uuids.services.device.characteristics.deviceName,
      });
      deviceInfo.name = BleUtils.bleValueToString( bleValue );
      bleValue = await dispatch('queryValue', {
        serviceUuid: ScancorderConfig.uuids.services.device.uuid,
        characteristicUuid: ScancorderConfig.uuids.services.device.characteristics.firmwareVersion,
      });
      deviceInfo.firmwareVersion = BleUtils.bleValueToString( bleValue );
      bleValue = await dispatch('queryValue', {
        serviceUuid: ScancorderConfig.uuids.services.device.uuid,
        characteristicUuid: ScancorderConfig.uuids.services.device.characteristics.hardwareVersion,
      });
      deviceInfo.hardwareVersion = BleUtils.bleValueToString( bleValue );
      return deviceInfo;
    },
    async readSensorHeadInfo( { dispatch }, state ) {
      let sensorHead = defaultDeviceInfo().sensorHead;
      let ble = ScancorderConfig.uuids.services.sensorhead;
      
      let bleValue = await dispatch('queryValue', {
        serviceUuid: ble.uuid,
        characteristicUuid: ble.characteristics.sensorConfiguration,
      });
      console.log( BleUtils.bleValueToString( bleValue ) );
      let config = JSON.parse( BleUtils.bleValueToString( bleValue ) );
      sensorHead.type = config.type;
      sensorHead.name = config.name;
      sensorHead.driver = config.driver;
      sensorHead.sensor = config.sensor;
      
      bleValue = await dispatch('queryValue', {
        serviceUuid: ble.uuid,
        characteristicUuid: ble.characteristics.sensorSerial,
      });
      sensorHead.serial = BleUtils.bleValueToString( bleValue );
      
      bleValue = await dispatch('queryValue', {
        serviceUuid: ble.uuid,
        characteristicUuid: ble.characteristics.sensorStatistics,
      });
      sensorHead.statistiscs = JSON.parse( BleUtils.bleValueToString( bleValue ) );
      
      let jsonIsValid = false;
      let additionalString = "";
      let addInfo = {};
      while ( !jsonIsValid ) {

        console.log( "Reading additional info" );

        bleValue = await dispatch('queryValue', {
          serviceUuid: ble.uuid,
          characteristicUuid: ble.characteristics.additionalInfo,
        });

        console.log( BleUtils.bleValueToString( bleValue ) );
        additionalString += BleUtils.bleValueToString( bleValue );
        try {
          console.log( "Trying to parse ", additionalString );
          addInfo = JSON.parse( additionalString );
          jsonIsValid = true;
        } catch {
          console.log( "not valid yet, let's read more... " );
        }
      }
      sensorHead.additionalInfo = addInfo;
      
      console.log( sensorHead );
      return sensorHead;
    },
    async disconnect( { commit, state } ) {
        if ( state.server != null )
          state.server.disconnect();
        commit( 'setServer', null );
        commit( 'setDevice', null );
        commit( 'resetServices' );
        commit( 'resetCharacteristics' );
        commit( 'resetDeviceInfo' );
    },
    async queryService( { dispatch, commit, state, getters  }, { serviceUuid } ) {
      if (serviceUuid.startsWith('0x')) {
        serviceUuid = parseInt(serviceUuid);
      }
      if ( !state.services.has( serviceUuid ) ) {
        let service = await BleUtils.retriableAction( () => { return state.server.getPrimaryService( serviceUuid ); } );
        commit( 'setService', service );
      }
      return state.services.get( serviceUuid );
    },
    async queryCharacteristic( { dispatch, commit, state, getters  }, { serviceUuid, characteristicUuid } ) {
      if (characteristicUuid.startsWith('0x')) {
        characteristicUuid = parseInt(characteristicUuid);
      }
      if ( !state.characteristics.has( characteristicUuid ) ) {
        let service = await dispatch( 'queryService', { serviceUuid: serviceUuid } );
        let characteristic = await BleUtils.retriableAction( () => { return service.getCharacteristic( characteristicUuid ); } );
        commit( 'setCharacteristic', characteristic );
      }
      return state.characteristics.get( characteristicUuid );
    },
    async queryValue( { dispatch, commit, state, getters  }, { serviceUuid, characteristicUuid } ) {
      let characteristic = await dispatch( 
        'queryCharacteristic', 
        {
          serviceUuid: serviceUuid,
          characteristicUuid: characteristicUuid
        } 
      );
      return await BleUtils.retriableAction( () => { return characteristic.readValue(); } );
    },
    async subscribeNotification( 
      { dispatch }, 
      { serviceUuid, characteristicUuid, callback } ) {
        let subscriptionInfo = {
          serviceUuid: serviceUuid, 
          characteristicUuid: characteristicUuid, 
          callback: callback,
        }
        let characteristic = await dispatch('queryCharacteristic', {
          serviceUuid: serviceUuid,
          characteristicUuid: characteristicUuid
        } );
        await BleUtils.retriableAction( () => { return characteristic.startNotifications(); } );
        
        subscriptionInfo.wrappedCallback = ( event ) => {
          let value = event.target.value;
          callback( value );
        }

        characteristic.addEventListener(
          'characteristicvaluechanged',
          subscriptionInfo.wrappedCallback
        );
        return subscriptionInfo;
    },
    async unsubscribeNotification( 
      { dispatch, commit, state, getters  }, 
      subscriptionInfo ) {
        let characteristic = await dispatch('queryCharacteristic', {
          serviceUuid: subscriptionInfo.serviceUuid,
          characteristicUuid: subscriptionInfo.characteristicUuid
        } );
        characteristic.removeEventListener(
          'characteristicvaluechanged', 
          subscriptionInfo.wrappedCallback
        );
        BleUtils.retriableAction( () => { return characteristic.stopNotifications() } );
    },
    async readMeasurementSettings( { dispatch, state }, fakeMode = false ) {
      let measurementSettings = {};
      let bleValue = 0;
      console.log( fakeMode );
      if ( fakeMode ) {
        return {
          exposuretime: 99,
          gain: 0,
          ledCurrent: 20,
        }
      }

      bleValue = await dispatch('queryValue', {
        serviceUuid: ScancorderConfig.uuids.services.sensorhead.uuid,
        characteristicUuid: ScancorderConfig.uuids.services.sensorhead.characteristics.exposureTime
      });
      measurementSettings.exposuretime = BleUtils.bleValueToInt32( bleValue );

      bleValue = await dispatch('queryValue', {
        serviceUuid: ScancorderConfig.uuids.services.sensorhead.uuid,
        characteristicUuid: ScancorderConfig.uuids.services.sensorhead.characteristics.gain
      });
      measurementSettings.gain = BleUtils.bleValueToInt8( bleValue );

      if ( ! state.deviceInfo.sensorHead.driver ) {
        bleValue = await dispatch('queryValue', {
          serviceUuid: ScancorderConfig.uuids.services.sensorhead.uuid,
          characteristicUuid: ScancorderConfig.uuids.services.sensorhead.characteristics.ledCurrent
        });
        measurementSettings.ledCurrent = BleUtils.bleValueToInt8( bleValue );
      }
      return measurementSettings;
    }
  }
  
  // mutations
  const mutations = {
      setDevice (state, device ) {
        console.log( device );
        state.device = device;
      },
      setServer (state, server ) {
        state.server = server;
      },
      setService ( state, service ) {
        state.services.set( service.uuid, service );
      },
      setCharacteristic ( state, characteristic ) {
        state.characteristics.set( characteristic.uuid, characteristic );
      },
      resetServices (state ) {
        state.characteristics = new Map();
      },
      resetCharacteristics (state ) {
        state.services = new Map();
      },
      resetDeviceInfo( state ) {
        state.deviceInfo = defaultDeviceInfo();
      },
      setDeviceInfo( state, deviceInfo ) {
        state.deviceInfo = deviceInfo;
      }
      
  }
  
  export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
  }