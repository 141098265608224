
import store from '@/store';
import sleep from '@/utils/sleep';

const BLE_ACTION_RETRIES = 30;
const retryPauseMaxMs = 100;
    
const BleUtils = {
    bleValueToString( bleValue ) {
        let decoder = new TextDecoder("utf-8");
        return decoder.decode( bleValue );
    },
    bleValueToInt( bleValue ) {
        return this.bleValueToInt32( bleValue );
    },
    bleValueToInt32( bleValue ) {
        return new Int32Array( bleValue.buffer )[0];
        //return this.ntohl( bleValue.getInt32() );
    },
    bleValueToInt16( bleValue ) {
        return new Int16Array( bleValue.buffer )[0];
  //      return this.ntohs( bleValue.getInt16() );
    },
    bleValueToInt8( bleValue ) {        
        return new Int8Array( bleValue.buffer )[0];
//        return bleValue.getInt8();
    },
    bleValueToUInt8( bleValue ) {        
        return new Uint8Array( bleValue.buffer )[0];
    },
    ntohs( int16Value ) {
        return ( ( 0xff & int16Value ) << 8 ) +
            ( ( 0xff00 & int16Value ) >> 8 );
    },
    ntohl( int32value ) {
        return 0 + 
        ( ( 0x000000ff & int32value ) << 24 ) +
        ( ( 0x0000ff00 & int32value ) << 8 ) +
        ( ( 0x00ff0000 & int32value ) >> 8 ) +
        ( ( 0xff000000 & int32value ) >> 24 );
    },
    logValue( bleValue, prefix = '' ) {
        let logString = prefix;
        logString += " (";
        logString += bleValue.byteLength;
        logString += ") ";
        logString += [...new Uint8Array( bleValue.buffer )]
            .map(x => x.toString(16).padStart(2, '0'))
            .join(' ');
        logString += " => ";
        if ( bleValue.byteLength == 1 ) {
            logString += bleValue.getUint8();
        } else if ( bleValue.byteLength == 2 ) {
            logString += BleUtils.ntohs( bleValue.getUint16() );
        } else if ( bleValue.byteLength == 4 ) {
            logString += BleUtils.ntohl( bleValue.getUint32() );
        } else {
            // Try String interpretation
            logString += BleUtils.bleValueToString( bleValue );
        }
        
        console.log( logString );
        console.log( bleValue );
    },
    async subscribeNotification( notificationInfo ) {
        if ( notificationInfo == null || notificationInfo.subscriptionInfo != null )
            return notificationInfo;
        
        notificationInfo.subscriptionInfo = await store.dispatch('scancorder/subscribeNotification', {
            serviceUuid: notificationInfo.serviceUuid,
            characteristicUuid: notificationInfo.characteristicUuid,
            callback: notificationInfo.callback,
        });

        let bleValue = await store.dispatch('scancorder/queryValue', {
            serviceUuid: notificationInfo.serviceUuid,
            characteristicUuid: notificationInfo.characteristicUuid
        });
        notificationInfo.callback( bleValue );

        return notificationInfo;
    },
    async unsubscribeNotification( notificationInfo ) {
        if ( notificationInfo == null )
            return notificationInfo;

        if ( notificationInfo.subscriptionInfo != null ) {
            await store.dispatch('scancorder/unsubscribeNotification', notificationInfo.subscriptionInfo );
            notificationInfo.subscriptionInfo = null;            
        }
        return notificationInfo;
    },
    async retriableAction( fn ) {
      let retries = BLE_ACTION_RETRIES;
      while ( retries > 0 ) {
        try {
            console.log( fn );
            let result = await fn();
            console.log( 'success ', result );
            return result;
        } catch( error ) {
          console.log( 'ble function failed with: ', error );
          let pause = Math.random() * retryPauseMaxMs;
          console.log( 'Waiting ', pause, 'ms before retry. ', retries, ' left.' );
          retries--;
          if ( retries <= 0 ) {
            throw error;
          }
          await sleep( pause );
        }
      }
    }
}

export default BleUtils