<template>
  <v-btn
      id="newMeasurementButton"
      color="primary"
      icon="mdi-plus"
      elevation="20"
      style=""
      @click="startMeasurement()"
      >
    </v-btn>
</template>

<script>

import ScancorderConfig from '@/config/Scancorder';
import { mapGetters } from 'vuex';

export default {
  emits: [
    'fakeMeassurement'
  ],
  data() {
    return {
      measurementIInProgress: false,
    }
  },
  computed: {
      ...mapGetters( { 
        fakeMode: 'config/fakeMode',
      } ),
  },
  methods: {
    async startMeasurement() {
      console.log ( this.fakeMode );
      if ( this.fakeMode ) {
        this.$emit( 'fakeMeassurement' );
        return;
      }
      console.log( "Measuremnet started");
      this.measurementIInProgress = true;
      let measurementControlCharacteristic = await this.$store.dispatch('scancorder/queryCharacteristic', {
        serviceUuid: ScancorderConfig.uuids.services.sensorhead.uuid,
        characteristicUuid: ScancorderConfig.uuids.services.sensorhead.characteristics.measurementControl,
      });
      console.log( measurementControlCharacteristic );
      let encoder = new TextEncoder();
      let data = encoder.encode( 'start' );

      measurementControlCharacteristic.writeValueWithoutResponse( data.buffer );
      
      this.measurementIInProgress = false;
    },
  }
}
</script>

<style>

#newMeasurementButton {
  position: fixed;
  right: 32px;
  bottom: 76px;
  z-index: 2001;
}

</style>