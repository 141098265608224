<template>
    <div>
        <p>
            Type: {{ deviceInfo.sensorHead.type }}, {{ deviceInfo.sensorHead.name }} 
        </p>
        <p>
            sensorSerial: {{ deviceInfo.sensorHead.serial }}
        </p>
        <p>
            hasDedicatedSensor: {{ deviceInfo.sensorHead.sensor }}, hasDedicatedDriver: {{ deviceInfo.sensorHead.driver }}, 
            sensorConfig: {{ deviceInfo.sensorHead.type }}, 
        </p>
        <p>
            sensorStatistics: {{ deviceInfo.sensorHead.statistics }}, 
        </p>
        <p>
            additionalInfo: {{ deviceInfo.sensorHead.additionalInfo }}, 
        </p>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters({
            connected: 'scancorder/isConnected',
            deviceInfo: 'scancorder/deviceInfo',
        } )
    },
}
</script>

<style>

</style>