<template>
<v-menu
    v-model="menu"
    :close-on-content-click="false"
    anchor="top"
    >
    <template v-slot:activator="{ props }">      
      <v-btn
        id="setLabelButton"
        color="primary"
        variant="outlined"
        icon="mdi-card-text-outline"
        elevation="20"
        v-bind="props"
        >
      </v-btn>
    </template>

    <v-card id="labelCard" min-width="400">
      <v-card-text>
        <v-text-field
            label="Label"
            v-model="label"
          ></v-text-field>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    
    data() {
        return {
            label: "",
            menu: null,
        }
    },
    computed: {
        ...mapGetters({
          labelInStorage: 'config/label',
      } )
    },
    mounted() {
        this.label = this.labelInStorage;
    },
    watch: {
        label( newValue ) {
            console.log("Watch out!");
            this.setLabelInStorage( newValue );
        }
    },
    methods: {
        ...mapActions( {
            setLabelInStorage: 'config/setLabel',
        } )
    }
}
</script>

<style>

#setLabelButton {
  position: fixed;
  left: 32px;
  bottom: 140px;
  z-index: 2001;
}
</style>