<template>
  <v-card class="elevation-12">
        <v-toolbar dark color="primary">
            <v-toolbar-title>
                About
            </v-toolbar-title>
            <div>
                <span class="text-medium-emphasis">
                    v. {{ version }}
                </span>
            </div>
        </v-toolbar>
        <v-card-text>
            <v-container>
                <v-row
                    justify="center"
                    >
                    <v-col
                        cols="12"
                        sm="3"
                        class="d-flex justify-center align-center pa-10">
                        <v-img
                            id="compolyticsLogo"
                            aspect-ratio="1"
                            width="70%"
                            src="../assets/compolytics_logo.svg"
                            ></v-img>
                    </v-col>
                    <v-col>
                        <div class="justify-content-center text-center">
                            <h2>
                                <strong> CICADA </strong> <br>
                                <span class="text-medium-emphasis">
                                    Compolytics Integrated Control Application for Devices and Analysis
                                </span>
                            </h2>
                        </div>
                        <div class="justify-content-center mt-3">
                            <p class="text-center">
                                © 2022 Compolytics, 
                                <a href="http://compolytics.com/">compolytics.com</a>
                            </p>
                        </div>
                        <v-container>
                            <v-row>
                                <v-col>
                                    <div class="mt-3 text-center">
                                        <h3 class="mb-1">Development</h3>
                                        <p>
                                            <a href="https://spectelligence.com"> specTelligence GmbH </a>
                                        </p>
                                    </div>
                                </v-col>
                                <v-col>                                    
                                    <div class="mt-3 text-center">
                                        <h3 class="mb-1">Assets</h3>
                                        <p>
                                            Free SVG Background by 
                                            <a target="_blank" href="https://bgjar.com">
                                                BGJar
                                            </a>
                                        </p>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    data() {
        return {
            version: '0.10',
        }
    }

}
</script>

<style>
#compolyticsLogo {
    max-width: 200px;
}
</style>