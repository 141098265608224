<template>  
    <v-card class="elevation-12">
        <v-progress-circular
            v-if="loading" 
            indeterminate
            color="primary"
            class="m-2"
            ></v-progress-circular>
        <template v-else>
            <v-toolbar dark color="primary">
                <v-toolbar-title>Bluetooth required</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <p>
                    This web application is designed to communicate with a bluetooth device.
                    Currently bluetooth is not available on your system. Either it is not supported or not enabled.
                    
                    To enable experimental bluetooth support please enable the corresponding experimental flags:
                </p>
                <v-list>
                    <v-list-item>
                        <a href="about:flags/#enable-experimental-web-platform-features">
                            about:flags/#enable-experimental-web-platform-features
                        </a>
                    </v-list-item>
                    
                    <v-list-item>
                        <a href="about:flags/#enable-web-bluetooth-new-permissions-backend">
                            about:flags/#enable-web-bluetooth-new-permissions-backend
                        </a>
                    </v-list-item>
                </v-list>
            </v-card-text>
            <v-card-actions>
            </v-card-actions>
        </template>
    </v-card>
</template>

<script>
export default {
    data() {
        return {
            bluetoothAvailable: false,
            loading: true,
        }
    },
    mounted() {
        navigator.bluetooth.getAvailability().then( available => {
            this.loading = false;
            if (available) {
                this.bluetoothAvailable = true;
                this.$router.push( {name:"Device"})
            }
            else
                this.bluetoothAvailable = false;
        });
    }

}
</script>

<style>

</style>