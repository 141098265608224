<template>
    <v-card class="elevation-12">
        <v-toolbar dark color="primary">
            <v-toolbar-title>Login form</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
            <v-form>
                <v-text-field
                    prepend-icon="mdi-person"
                    name="login"
                    v-model="credentials.email"
                    label="Login"
                    type="text"
                    autocomplete="current-username"
                ></v-text-field>
                <v-text-field
                    id="password"
                    v-model="credentials.password"
                    prepend-icon="mdi-lock"
                    name="password"
                    label="Password"
                    type="password"
                    autocomplete="current-password"
                ></v-text-field>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="submitForm">Login</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
   name: 'BasicLogin',
   data() {
       return {
           credentials: {
               email: null,
               password: null,
           }
       };
   },
   methods: {
       async submitForm() {
            console.dir( this.credentials );
            await this.$store.dispatch('auth/authenticate', this.credentials );
            if ( this.$store.state.auth.user_id != null ) {
                console.log("wuhu!!!");
                this.$router.push( { name: "Home" } );
            } else
                console.error( "nope.");
       }
   }
};
</script>

<style>

</style>