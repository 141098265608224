import { createStore, createLogger } from 'vuex'
import createPersistedState from "vuex-persistedstate";
import auth from './modules/auth'
import scancorder from './modules/scancorder';
import config from './modules/config';

const debug = process.env.NODE_ENV !== 'production'
//const debug = false;

var plugins = [
  createPersistedState({
    paths: [
      'auth',
      'config',
    ],
  })
]

if ( debug ) {
  plugins.push( createLogger() );
}

export default createStore({
  modules: {
    auth,
    scancorder,
    config
  },
  strict: debug,
  plugins: plugins, 
})