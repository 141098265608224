<template>
  <div class="device">
    <scan-corder-device />    
  </div>
</template>

<script>
import ScanCorderDevice from '../components/Scancorder/Device.vue'

export default {
  name: 'DeviceView',
  components: {
    ScanCorderDevice,
  },
  data() {
    return {
      updateFile: [],
      uploadProgress: 0
    }
  },
  mounted() {
  },
  methods: {
  }
}
</script>
