<template>
<div>
    <v-btn
        @click="show = !show"
        >
        <v-icon>
            <template v-if="level > 90">
                mdi-battery
            </template>
            <template v-else-if="level > 0 ">
                mdi-battery-{{level}}
            </template>
            <template v-else>
                mdi-battery-alert-variant-outline
            </template>
        </v-icon>   
    </v-btn>
    <v-tooltip
        anchor="start"
        activator="parent"
        v-model="show"
        >
        {{level}}%, 
        <v-icon>
            mdi-battery-charging
        </v-icon> {{ batteryVoltage }}mV, 
        <v-icon>
            mdi-usb
        </v-icon> {{ usbVoltage }}mV 
    </v-tooltip>
    
</div>
</template>

<script>
import ScancorderConfig from '@/config/Scancorder';
import BleUtils from '@/utils/BleUtils';

export default {
    props: {
    },
    data: () => {
        return {
            show: false,
            level: 10,
            levelSubscriptionInfo: null,
            batteryVoltage: 1000,
            voltageSubscriptionInfo: null,
            usbVoltage: 1200,
            usbSubscriptionInfo: null,

            bleRegistration: [],
        }
    },
    created() {
        this.bleRegistration = [
                {
                    serviceUuid: ScancorderConfig.uuids.services.power.uuid,
                    characteristicUuid: ScancorderConfig.uuids.services.power.characteristics.batteryLevel,
                    callback: this.onBatteryLevelChange,
                    subscriptionInfo: null,
                },
                {
                    serviceUuid: ScancorderConfig.uuids.services.power.uuid,
                    characteristicUuid: ScancorderConfig.uuids.services.power.characteristics.batteryVoltage,
                    callback: this.onBatteryVoltageChange,
                    subscriptionInfo: null,
                },
                {
                    serviceUuid: ScancorderConfig.uuids.services.power.uuid,
                    characteristicUuid: ScancorderConfig.uuids.services.power.characteristics.usbVoltage,
                    callback: this.onUsbVoltageChange,
                    subscriptionInfo: null,
                },                
            ]
    },
    mounted() {
        this.subscribeNotifications();
    },
    beforeUnmount() {
        this.unsubscribeNotifications();
    },
    methods: {
        async subscribeNotifications() {
            for ( const element of this.bleRegistration ) {
                await BleUtils.subscribeNotification( element );
            }
        },
        async unsubscribeNotifications() {
            for ( const element of this.bleRegistration ) {
                await BleUtils.unsubscribeNotification( element );
            }
        },
        onBatteryLevelChange( bleValue ) {
            this.level = BleUtils.bleValueToUInt8( bleValue );
        },
        onBatteryVoltageChange( bleValue ) {
            let voltage = BleUtils.bleValueToInt16( bleValue );
            this.batteryVoltage = voltage;
        },
        onUsbVoltageChange( bleValue ) {
            let voltage = BleUtils.bleValueToInt16( bleValue );
            this.usbVoltage = voltage;
        }
    }

}
</script>

<style>

</style>