function getRandomInt(max) {
    return Math.floor(Math.random() * max);
}

export default { 
    generateRandomData( cols, rows = 1 ) {
        let fake_data = [];
        const max_value = 2160;

        for ( let row = 0; row < rows; row++ ) {
            let fake_vector = [];
            for ( let col = 0; col < cols; col++ ) {
                fake_vector.push( getRandomInt( max_value ) );
            }
            fake_data.push( fake_vector );
        }
        return fake_data;
    }
}