import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView'
import CreditsView from '@/views/CreditsView'
import LoginView from '@/views/LoginView'
import LibraryView from '@/views/LibraryView'
import DeviceView from '@/views/DeviceView'
import BluetoothNotAvailableView from '@/views/BluetoothNotAvailableView'

import store from '@/store';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/scan',
    name: 'Scan',
    component: HomeView
  },
  {
    path: '/credits',
    name: 'Credits',
    component: CreditsView
  },
  {
    path: '/device',
    name: 'Device',
    component: DeviceView
  },
  {
    path: '/library',
    name: 'Library',
    component: LibraryView
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView
  },
  {
    path: '/noBle',
    name: 'NoBluetooth',
    component: BluetoothNotAvailableView
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach( ( to, ) => {
  if ( to.name != 'Login' && !store.getters['auth/isAuthenticated'] )
    return {
      name: 'Login'
    };

  if ( to.name == 'Login' && store.getters['auth/isAuthenticated'] )
    return {
      name: 'Home'
    };

  return true;
} )

export default router
