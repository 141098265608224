<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    anchor="top"
    >
    <template v-slot:activator="{ props }">      
      <v-btn
        id="measurementPreferencesButton"
        color="primary"
        variant="outlined"
        icon="mdi-cog-outline"
        elevation="20"
        v-bind="props"
        >
      </v-btn>
    </template>

    <v-card id="preferenceCard" min-width="300">
      <v-card-text>
        <ble-slider
          name="Gain"
          :min=0
          :max=10
          :type="'uint8'"
          :serviceUuid="serviceUuids.uuid"
          :characteristicUuid="serviceUuids.characteristics.gain"
          :showGainDetails="true"
          />
        <ble-slider
          name="Exposure time [ms]"
          :min=1
          :max=1000
          :type="'uint32'"
          :serviceUuid="serviceUuids.uuid"
          :characteristicUuid="serviceUuids.characteristics.exposureTime"
          />
        <ble-slider
          name="LED current [mA]"
          v-if="!hasDedicatedDriver"
          :min=4
          :max=200
          :type="'uint8'"
          :serviceUuid="serviceUuids.uuid"
          :characteristicUuid="serviceUuids.characteristics.ledCurrent"
          />
          <v-text-field
            type="text"
            v-model="tempUrl"
            prepend-icon="mdi-web"
            density="compact"
            messages="Recall Url" />
        <p>
          Settings are only written once the dialog is closed
        </p>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import BleSlider from './BleSlider.vue'
import ScancorderConfig from '@/config/Scancorder';
import { mapActions, mapGetters } from 'vuex';
export default {
  components: { BleSlider },

  computed: {
      ...mapGetters({
          hasDedicatedDriver: 'scancorder/hasDriver',
          storedUrl: 'config/recallUrl',
      } )
  },
  mounted() {
    this.tempUrl = this.storedUrl;
  },
  data() {
    return {
      serviceUuids: ScancorderConfig.uuids.services.sensorhead,
      menu: null,
      tempUrl: '',
    }
  },
  watch: {
    menu() {
      console.log( this.menu );
      this.storeNewRecallUrl( this.tempUrl );
    }
  },
  methods: {
    ...mapActions( {
      storeNewRecallUrl: 'config/setRecallUrl'
    } )
  }
}
</script>

<style scoped>

#measurementPreferencesButton {
  position: fixed;
  left: 32px;
  bottom: 76px;
  z-index: 2001;
}

#preferenceCard {
  min-width: 300px;
  border-radius: 1em;
  margin-bottom: 0.5em;
}
.v-list-item {
  flex-direction: column;
}
.slider-container {
  width: 100%;
  margin-left: 1em;
  margin-right: 1em;
}
</style>