// Auth store
/* eslint-disable */
// initial state
const state = () => ( {
  user_id: null,
} )

// getters
const getters = {
  isAuthenticated: (state, getters, rootState) => {
    return state.user_id != null;
    },
}

// actions
const actions = {
  async authenticate ({ commit, state }, credentials) {
    
    try {
      console.dir( credentials );
      console.log( credentials.email == "melchert@spectelligence.com" );
      console.log( credentials.password == "hello123" );
        if ( credentials.email == "melchert@spectelligence.com" 
            && credentials.password == "hello123" )
            commit( 'login', 1 );
    } catch ( e ) {
        commit( 'logout' );
    }
    
  },
  async logout ( { commit, state } ) {
    commit( 'logout' );
  }  
}

// mutations
const mutations = {
    login (state, payload ) {
      console.log( payload );
        state.user_id = payload;
    },
    logout (state ) {
        state.user_id = null;
    },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}