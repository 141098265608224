// initial state
const state = () => ( {
    autoReconnect: false,
    autoReconnectDeviceId: null,
    label: "",
    recallUrl: "https://tuezaqoybj.execute-api.eu-central-1.amazonaws.com/default/ThreeSugars",
    whiteBalance: null,
    fakeMode: false,
  } )
  
  // getters
  const getters = {
    autoReconnect: (state ) => {
      return ( state.autoReconnect );
    },
    deviceId: (state ) => {
      return ( state.autoReconnectDeviceId );
    },
    label: ( state ) => {
      return state.label;
    },
    recallUrl: ( state ) => {
      return state.recallUrl;
    },
    whiteBalance: ( state ) => {
      return state.whiteBalance;
    },
    fakeMode: ( state ) => {
      return state.fakeMode;
    },
  }
  
  // actions
  const actions = {
    async enableAutoReconnect( { commit }, bleDevice ) {      
        commit( 'setAutoReconnect', true );
        commit( 'setAutoReconnectDeviceId', bleDevice.id );
    },
    async disableAutoReconnect( { commit } ) {
        commit( 'setAutoReconnect', false );
        commit( 'setAutoReconnectDeviceId', null );
    },
    async setLabel( { commit }, label ) {
      commit( 'setLabel', label );
    },
    async setRecallUrl( { commit }, url ) {
      commit( 'setRecallUrl', url );
    },
    async setWhitebalance( { commit }, whiteBalance ) {
      if ( whiteBalance != null && whiteBalance.values ) {
        for( let row = 0; row < whiteBalance.values.length; row++ ) {
          for ( let col = 0; col < whiteBalance.values[row].length; col++ ) {
            if ( whiteBalance.values[row][col] <= 0 )
              whiteBalance.values[row][col] = 1;
          }
        }
      }
      commit( 'setWhitebalance', whiteBalance );
    },
    setFakeMode( {commit} , fakeMode ) {
      commit( 'setFakeMode', fakeMode );
    },
    disableFakeMode( {commit} ) {
      commit( 'setFakeMode', false );
    }
  }
  
  // mutations
  const mutations = {
      setAutoReconnect ( state, reconnect ) {
        state.autoReconnect = reconnect;
      },
      setAutoReconnectDeviceId ( state, deviceId ) {
        state.autoReconnectDeviceId = deviceId;
      },
      setLabel( state, labelValue ) {
        state.label = labelValue;
      },
      setRecallUrl( state, url ) {
        state.recallUrl = url;
      },
      setWhitebalance( state, whiteBalance ) {
        state.whiteBalance = whiteBalance;
      },
      setFakeMode( state, fakeMode ) {
        state.fakeMode = fakeMode;
      },
  }
  
  export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
  }