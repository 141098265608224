
function decimalToHex(d) {
    d = Math.round(d);
    var hex = d.toString(16);
    while (hex.length < 2) {
        hex = "0" + hex;
    }

    return hex;
}

function linearinterpolate( x, x1, y1, x2, y2 ) {
    return y1 + ( y2 - y1 ) * ( x - x1 ) / (x2 - x1 );
}

export function convertWavelengthToColor( wavelength )
{
    const w = wavelength;

    let red = 0;
    let green = 0;
    let blue = 0;
    if (w >= 380 && w < 440)
    {
        red   = -(w - 440) / (440 - 380);
        green = 0.0;
        blue  = 1.0;
    }
    else if (w >= 440 && w < 490)
    {
        red   = 0.0;
        green = (w - 440) / (490 - 440);
        blue  = 1.0;
    }
    else if (w >= 490 && w < 510)
    {
        red   = 0.0;
        green = 1.0;
        blue  = -(w - 510) / (510 - 490);
    }
    else if (w >= 510 && w < 580)
    {
        red   = (w - 510) / (580 - 510);
        green = 1.0;
        blue  = 0.0;
    }
    else if (w >= 580 && w < 645)
    {
        red   = 1.0;
        green = -(w - 645) / (645 - 580);
        blue  = 0.0;
    }
    else if (w >= 645 && w < 781)
    {
        red   = 1.0;
        green = 0.0;
        blue  = 0.0;
    }
    else if ( w >= 781 ) 
    {
        // Infrared should be represented by 
        // some kind of "dark red", darkness
        // is controlled via facotr below
        red   = 1.0;
        green = 0.0;
        blue  = 0.0;
    }
    

    // Let the intensity fall off near the vision limits

    let factor = 1;
    if (w >= 380 && w < 420)
        factor = linearinterpolate( w, 380, 0.3, 420, 1.0 );
    else if (w >= 420 && w < 701)
        factor = 1.0;
    else if (w >= 701 && w < 781)
        factor = linearinterpolate( w, 700, 1.0, 780, 0.5 );
    else if (w >= 781 && w < 1200) // gradually further decrease the intensity for ir
        factor = linearinterpolate( w, 780, 0.5, 1000, 0.1 );
    else
        factor = 0.0;

    var gamma = 1.1;
    var R = (red   > 0 ? 255*Math.pow(red   * factor, gamma) : 0);
    var G = (green > 0 ? 255*Math.pow(green * factor, gamma) : 0);
    var B = (blue  > 0 ? 255*Math.pow(blue  * factor, gamma) : 0); 

    var hex = "#" + decimalToHex(R) + decimalToHex(G) + decimalToHex(B);
    return hex;
}