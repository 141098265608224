<template>
    <v-tooltip
        anchor="end"
        >
        <template v-slot:activator="{ props }">
            <v-btn
                v-if="hasSautratedValues"
                id="saturationAlertButton"
                color="#821a1a"
                icon="mdi-alert-circle"
                elevation="20"
                style=""
                v-bind="props"
                >
                Button
            </v-btn>
        </template>
        <span>Saturation Alert</span>
    </v-tooltip>
</template>

<script>

const SATURATION_THRESHOLD = 65000;

export default {
    props: [
        'values',
    ],
    computed: {
        hasSautratedValues() {
            return this.values.some( vector => vector.some( val => val >= SATURATION_THRESHOLD ) );
        }
    }
}
</script>

<style>

#saturationAlertButton {
  position: fixed;
  left: 32px;
  top: 82px;
  z-index: 2001;
}
</style>