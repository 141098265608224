<template>  
    <v-card class="elevation-12">
        <update-dialog 
            :show="shouldUpdateStart"
            @finish="updateFinished"
            @cancel="updateCanceled"
            @error="updateError"
            />
        <v-toolbar dark color="primary">
            <v-btn
                v-if="connected"
                >
                <v-icon 
                    class="me-2">
                    mdi-bluetooth-connect            
                </v-icon>
            
                <v-menu
                    activator="parent"
                    anchor="end"
                    v-model="bleMenu"
                    >
                    <v-list>
                        <v-list-item @click="disconnectDevice()">
                            <v-list-item-avatar>
                                <v-icon icon="mdi-bluetooth-off"></v-icon>
                            </v-list-item-avatar>
                            <v-list-item-title> disconnect </v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="updateFirmware()">
                            <v-list-item-avatar>
                                <v-icon icon="mdi-arrow-up-circle-outline"></v-icon>
                            </v-list-item-avatar>
                            <v-list-item-title> update </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-btn>
            <v-progress-circular
                v-if="connecting" 
                indeterminate
                color="primary"
                class="me-2"
                ></v-progress-circular>
            <v-toolbar-title>
                Scancorder Device
            </v-toolbar-title>
            <v-spacer/>
            <battery-indicator v-if="connected" />
            <v-btn 
                v-else 
                variant="outlined" 
                color="dark"
                :disabled="connecting"
                @click="connectDevice(null)"
                >
                Connect
            </v-btn>
            <!-- <v-btn 
                v-else 
                variant="outlined" 
                color="dark"
                :disabled="connecting"
                >
                Connect
                <v-menu 
                    activator="parent"
                    anchor="bottom end"
                    transition="slide-y-transition"
                    color="primary"
                    v-model="deviceMenu"
                    >
                    <v-list>
                        <v-list-item 
                            v-for="(deviceItem, i) in devices"
                            :key="i"
                            :value="deviceItem"
                            active-color="primary">
                            <v-list-item-title @click="connectDevice(deviceItem)"> {{deviceItem.name}} </v-list-item-title>
                            
                            <template v-slot:append>
                                <v-list-item-avatar end>
                                    <v-btn @click="removeDevice(deviceItem)">
                                        <v-icon>
                                            mdi-delete-forever-outline
                                        </v-icon>
                                    </v-btn>
                                </v-list-item-avatar>
                            </template>
                        </v-list-item>
                        <v-list-item @click="connectDevice(null)">
                            <v-list-item-avatar>
                                <v-icon icon="mdi-plus"></v-icon>
                            </v-list-item-avatar>
                            <v-list-item-title> new </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-btn> -->
        </v-toolbar>
        <v-card-text v-if="connected">
            <device-info />
            <sensorhead-info />
            <!-- <v-checkbox
                v-model="autoReconnect"
                label="automatically reconnect to this device"
                color="secondary"
                hide-details
            ></v-checkbox> -->
        </v-card-text>
    </v-card>
</template>

<script>
import { mapGetters } from 'vuex';

import BatteryIndicator from './BatteryIndicator.vue';
import DeviceInfo from './DeviceInfo.vue';
import SensorheadInfo from './SensorheadInfo.vue';
import UpdateDialog from './UpdateDialog.vue'

export default {
    components: { 
        BatteryIndicator,
        DeviceInfo,
        SensorheadInfo,
        UpdateDialog
    },
    name: "ScanCorderDevice",
    data() {
        return {
            devices: [],
            connecting: false,
            autoReconnect: false,
            startUpdate: false,
            bleMenu: false,
            deviceMenu: false,
        };
    },
    computed: {
        ...mapGetters({
            connected: 'scancorder/isConnected',
            connectedDevice: 'scancorder/device',
            shouldReconnect: 'config/autoReconnect',
            reconnectDeviceId: 'config/deviceId',
        } ),
        shouldUpdateStart() {
            return this.startUpdate;
        }
    },
    watch: {
        autoReconnect( newValue ) {
            if ( newValue == true ) {
                console.log( "store auto reconnect device" );
                this.$store.dispatch('config/enableAutoReconnect', this.connectedDevice);
            } else {
                console.log( "remove auto reconnect device" );
                this.$store.dispatch('config/disableAutoReconnect' );
            }
        }  
    },
    mounted() {
        this.loadDevices().then( () => {
            console.log( "Devices loaded" );
            if ( this.shouldReconnect ) {
                console.log( "Trying to automatically connect to device with id " + this.reconnectDeviceId );
                this.devices.some( (device)  => {
                    if ( device.id === this.reconnectDeviceId ) {
                        console.log( "Found device with device Id " + device.id );
                        this.connectDevice( device );
                        return true;
                    }
                });
            }
        } );
    },
    methods: {
        async connectDevice( device ) {
            this.deviceMenu = false;
            this.connecting = true;
            console.log('Connecting...' + device );
            try {
                await this.$store.dispatch('scancorder/connect', device);
            } catch ( error ) {
                console.error( error );
            }
            this.connecting = false;
            if ( !device )
                return;
            if ( this.shouldReconnect && this.reconnectDeviceId === device.id ) {
                this.autoReconnect = true;
            } 
        },
        async disconnectDevice() {    
            console.log('Disconnecting...' );
            await this.$store.dispatch( 'scancorder/disconnect' );
        },
        async loadDevices() {
            console.log( "Loading devices");
            this.devices = await this.$store.dispatch('scancorder/getDevices');
        },
        async removeDevice( device ) {
            this.deviceMenu = false;
            console.dir( device );
            if ( device.id == this.reconnectDeviceId )
                this.$store.dispatch('config/disableAutoReconnect' );
            this.devices = await this.$store.dispatch('scancorder/removeDevice', device );
        },
        updateFirmware() {
            this.bleMenu = false;
            this.startUpdate = true;
        },
        updateFinished() {
            this.startUpdate = false;
            console.log( "Firmware update finished successfully. Will reload page in 5 Seconds." );
            setTimeout( () => {
                window.location.reload();
            }, 5000 );
        },
        updateCanceled() {
            this.startUpdate = false;
        },
        updateError( error ) {
            this.startUpdate = false;
            console.error( 'FW Update error ', error );
        }
    }

}
</script>

<style>

</style>