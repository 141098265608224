<template>
  <div id="library"> 
    <div id="library-control">
      <v-dialog
        v-model="dialog"
        persistent
        id="saveDialog"
        v-if="data.length > 0"
        >
        <template v-slot:activator="{ props }">
          <v-btn
            class="ma-2"
            color="primary"
            v-bind="props"
            :disabled="data.length == 0"
            >
            Save Data
          </v-btn>
        </template>
        <v-card class="mt-4 elevation-12">
            <v-toolbar dark color="primary">
                Save Data
            </v-toolbar>
            <v-card-text>
              <v-text-field
                  label="Filename"
                  v-model="saveFilename"
                ></v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-btn
                    @click="saveData"
                    :disabled="( !saveFilename || saveFilename.length == 0 )"
                    >
                    Save
                </v-btn>
                <v-btn
                    @click="dialog = false"
                    >
                    Cancel
                </v-btn>
            </v-card-actions>
        </v-card>
      </v-dialog>
      <v-btn
        class="ma-2"
        color="primary"
        @click="setWhitebalance(null)"
        v-if="whitebalance"
        >
        Clear Whitebalance
      </v-btn>
      <v-btn
        class="ma-2"
        color="primary"
        @click="clearData"
        v-if="data.length > 0"
        >
        Clear Data
      </v-btn>
    </div>
    <div 
      v-if="data.length == 0"
      >
      <v-card>
      <v-card-text>
        <span>
          No data available.
        </span>
      </v-card-text>
    </v-card>
    </div>
    <v-card 
      v-for="measurement in data"
      v-bind:key="measurement.id"
      class="elevation-12"
      :style="{marginBottom:'1em'}"
      >
      <v-card-text>
        {{ measurement }}
      </v-card-text>
    </v-card>
  </div>
</template>

<script>

import mdb from '@/utils/MeasurementDatabase'
import { saveAs } from 'file-saver';
import dateformat from 'dateformat';
import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
      data: [],
      saveFilename: "",
      dialog: false,
    }
  },
  computed: {
    ...mapGetters( { 
      whitebalance: 'config/whiteBalance'
    } ),
  },
  mounted() {
    this.loadData();
    let date = Date.now();
    this.saveFilename = dateformat( date, 'yyyymmddHHMM".json"' );
  },
  methods: {
    ...mapActions( {
      setWhitebalance: 'config/setWhitebalance',
    } ),
    async loadData() {
      await mdb.setup();
      this.data = await mdb.db.getAll('measurements');
    },
    async clearData() {
      await mdb.setup();
      await mdb.db.clear('measurements')
      this.loadData();
    },
    async saveData() {
      if ( !this.saveFilename.endsWith( ".json" ) ) {
        this.saveFilename += ".json";
      }
      let saveData = JSON.stringify( this.data );
      var blob = new Blob( [saveData], {type: "text/plain;charset=utf-8"});
      saveAs( blob, this.saveFilename );
      this.dialog = false;
    }
  }

}
</script>

<style>
#library-control {
  display: flex;
  justify-content: center;
}
</style>