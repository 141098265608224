<template>
    <div>
    <v-btn
        id="saveMeasurementButton"
        color="primary"
        icon="mdi-content-save-outline"
        elevation="20"
        style=""
        :disabled="isSaving"
        @click="saveData()"
        @contextmenu.prevent="saveWhiteBalance()"
        >
    </v-btn>
    <v-dialog
        v-model="isSaving"
        >
        <v-card>
            <v-card-text>
                {{ dialogMessage }}
            </v-card-text>
        </v-card>
    </v-dialog>
    </div>
</template>

<script>
import { mapGetters, mapActions  } from 'vuex';

import mdb from '@/utils/MeasurementDatabase'


export default {
    props: [
        'values'
    ],
    computed: {
      ...mapGetters({
          deviceInfo: 'scancorder/deviceInfo',
          label: 'config/label',
          fakeMode: 'config/fakeMode',
          whitebalance: 'config/whiteBalance',
      } ),        
    },
    created() {

    },
    data() {
        return {
            isSaving: false,
            dialogMessage: "",
        }
    },
    methods: {
        ...mapActions({
            measurementSettings: 'scancorder/readMeasurementSettings',
            storeWhiteBalance: 'config/setWhitebalance',
        }),
        async saveData() {
            this.isSaving = true;
            this.dialogMessage = "Saving Measurement...";
            await mdb.setup();
            let settings = await this.measurementSettings( this.fakeMode );
            let dataToStore = {
                timestamp: +Date.now(),
                device: this.deviceInfo,
                values: this.values,
                settings: settings,
                label: this.label,
            };
            
                console.log( "storing");
            let whitebalance = this.whitebalance;
                console.log( whitebalance );
            if ( whitebalance ) {
                console.log( "storing with wb");
              dataToStore.whitebalance = whitebalance
              dataToStore.valuesBalanced = JSON.parse( JSON.stringify( dataToStore.values ) );
              if ( whitebalance.values.length == dataToStore.valuesBalanced.length && 
                whitebalance.values[0].length == dataToStore.valuesBalanced[0].length ) {
                for (let row = 0; row < dataToStore.valuesBalanced.length; row++ )
                  for ( let col = 0; col < dataToStore.valuesBalanced[row].length; col++ ) {
                    dataToStore.valuesBalanced[ row ][ col ] /= whitebalance.values[ row ][ col ];
                  }
              }
            }
            // Stringification and reparsing gets rid of all unused fields.
            dataToStore = JSON.parse( JSON.stringify( dataToStore ) ); 
            await mdb.db.add( 'measurements', dataToStore );
            setTimeout( () => {
                this.isSaving = false;
            }, 500 );
        },
        async saveWhiteBalance() {
            console.log('context please');
            this.dialogMessage = "Saving White Balance...";
            this.isSaving = true;
            let settings = await this.measurementSettings( this.fakeMode );
             let dataToStore = {
                timestamp: +Date.now(),
                device: this.deviceInfo,
                values: this.values,
                settings: settings,
                label: this.label,
            };
            dataToStore = JSON.parse( JSON.stringify( dataToStore ) ); 
            this.storeWhiteBalance( dataToStore );
            setTimeout( () => {
                this.isSaving = false;
                console.log( dataToStore );
            }, 500 );
        }
    }

}
</script>

<style>

#saveMeasurementButton {
  position: fixed;
  right: 32px;
  bottom: 140px;
  z-index: 2001;
}
</style>