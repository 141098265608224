import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueNumerals from 'vue-numerals';

loadFonts()

const app = createApp(App);

// Register plugins
app.use(store);
app.use(router);
app.use(vuetify);
app.use(VueAxios, axios);
app.use(VueNumerals);

app.mount('#app');
