<template>
    <basic-login/>
</template>

<script>
import BasicLogin from '@/components/BasicLogin.vue';

export default {
    components: {
        BasicLogin,
    }
}
</script>

<style>

</style>