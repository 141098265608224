<template>
  <div class="home">
    <MeasurementButton
      @fakeMeassurement="fakeMeaseurement"/>
    <SaveMeasurementButton
      v-if="values"
      :values="values"
      />
    <SaturationAlert
      v-if="values"
      :values="values"
    />
    <WhitbalanceMismatchAlert
      v-if="values"
      :values="values"
    />
    <PreferencesButton/>
    <SetLabelButton/>
    <ClassificationButton
      v-if="values"
      :values="values"
    />
    <v-card id="MeasurementValuesDisplayCard" class="elevation-12">
        <v-toolbar dark color="primary">
            <v-toolbar-title> 
              Scan Result 
            </v-toolbar-title>
            <v-tooltip 
                v-if="whitebalance"
                anchor="start"
                class="me-4"
                >
                <template v-slot:activator="{props}">
                  <v-icon
                    v-bind="props"
                    class="me-4"
                    >
                    mdi-abacus
                  </v-icon>
                </template>
                White balance active
              </v-tooltip>
            <div
              class="me-3"
              v-if="label && label.length > 1"
              >
                <span>
                    label: "{{ label }}"
                </span>
            </div>
            <div v-if="values && values.length > 1">
              <v-switch
                v-model="colorfull"
                color="success"
                hide-details
                >
              </v-switch>
            </div>
        </v-toolbar>
        <v-card-text>
          <template v-if="values">
          <measurement-data-chart
            v-if="values.length == 1" 
            :labels="sensorWavelengths"
            :values="chartValues"
            />
          <measurement-data-matrix 
            v-else 
            :rowLabels="ledWavelengths" 
            :columnLabels="sensorWavelengths" 
            :values="chartValues" 
            :colors="colors" />
          </template>
        </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ScancorderConfig from '@/config/Scancorder';
import BleUtils from '@/utils/BleUtils';
import Faker from '@/utils/Faker';
import MeasurementButton from '@/components/Scancorder/MeasurementButton';
import SaveMeasurementButton from '@/components/Scancorder/SaveMeasurementButton';
import SaturationAlert from '../components/ui/SaturationAlert.vue';
import WhitbalanceMismatchAlert from '../components/ui/WhitebalanceMismatchAlert.vue';
import PreferencesButton from '@/components/Scancorder/PreferencesButton.vue';

import { mapGetters } from 'vuex';
import MeasurementDataChart from '../components/ui/MeasurementDataChart.vue';
import MeasurementDataMatrix from '../components/ui/MeasurementDataMatrix.vue';

import { convertWavelengthToColor } from '@/utils/ColorUtils';
import SetLabelButton from '../components/ui/SetLabelButton.vue';
import ClassificationButton from '../components/ui/ClassificationButton.vue';

export default {
  name: 'HomeView',
  components: {
    MeasurementButton,
    SaveMeasurementButton,
    PreferencesButton,
    MeasurementDataChart,
    MeasurementDataMatrix,
    SaturationAlert,
    WhitbalanceMismatchAlert,
    SetLabelButton,
    ClassificationButton,
  },
  data() {
    return {
      dataString: null,
      dataStringComplete: false,
      testData: [1,2,3],
      measurementIInProgress: false,
      values: null,
      labels: null,
      colorfull: true,
    };
  },
  computed: {
      ...mapGetters({
          connected: 'scancorder/isConnected',
          deviceInfo: 'scancorder/deviceInfo',
          label: 'config/label',
          fakeMode: 'config/fakeMode',
          whitebalance: 'config/whiteBalance',
      } ),
      ledWavelengths() {
        try {
          return this.deviceInfo.sensorHead.additionalInfo.led_wl;
        } catch ( error ) {
          return Array( this.values.length ).fill( '100' );
        }
      },
      sensorWavelengths() {
        try {
          return this.deviceInfo.sensorHead.additionalInfo.sensor_wl;
        } catch (error ) {
          return [];
        }
      },
      colors() {
        try {
          const defaultColor = '#000000';
          let colors = [];
          if ( ! this.ledWavelengths || this.ledWavelengths.length < 1 ) {
            this.values.length;
            return Array( this.values.length ).fill( defaultColor );
          }
          this.ledWavelengths.forEach( wl => {
            if ( this.colorfull )
              colors.push( convertWavelengthToColor( wl ) );
            else
              colors.push( defaultColor );
          });
          return colors;
        } catch (error) {
          console.error( error );
          return [];
        }
      },
      chartValues() {
        console.log( "Computing chart values ");
        let chartValues = JSON.parse( JSON.stringify( this.values ) );
        let whitebalance = this.whitebalance;
        console.log( whitebalance );
        if ( whitebalance ) {
            console.log( "checking wb dimensions");
          if ( whitebalance.values.length == chartValues.length && 
            whitebalance.values[0].length == chartValues[0].length ) {
            console.log( "applying wb");
            for (let row = 0; row < chartValues.length; row++ )
              for ( let col = 0; col < chartValues[row].length; col++ ) {
                chartValues[ row ][ col ] /= whitebalance.values[ row ][ col ];
              }
          }
        }
        return chartValues;
      },
  },
  watch: {
    dataString( newValue ) {
      if ( newValue != null && newValue.endsWith(']]') ) {        
        console.log( "data string complete" );
        this.onMeasurementComplete( this.dataString );
      }
    }  
  },
  created() {
    this.bleSubscription = {
        serviceUuid: ScancorderConfig.uuids.services.sensorhead.uuid,
        characteristicUuid: ScancorderConfig.uuids.services.sensorhead.characteristics.measurementData,
        callback: this.onNewMeasurementData,
        subscriptionInfo: null,
    };
  },
  mounted() {
    if ( this.redirectIfNoDeviceConnected() )
      return;
    
    this.subscribeNotifcations();
  },
  beforeUnmount() {
    this.unsubscribeNotifcations();
  },
  methods: {
    redirectIfNoDeviceConnected() {
      if ( !this.connected && !this.fakeMode) {
        this.$router.push( {name:'Device' } );
        return true;
      }
      return false;
    },
    async subscribeNotifcations() {
      BleUtils.subscribeNotification( this.bleSubscription );
    },
    async unsubscribeNotifcations() {
      BleUtils.unsubscribeNotification( this.bleSubscription );
    },
    onNewMeasurementData( bleValue ) {
      // console.log( "new measurement data" );
      // BleUtils.logValue( bleValue, 'data: ' );
      let dataString = BleUtils.bleValueToString( bleValue );
      if ( dataString.length > 2 && dataString.startsWith( '[[' ) ) {
        this.dataString = dataString;
      } else if ( this.dataString != null ) {
        this.dataString += dataString;
      } else {
        this.dataString = null;
      }
    },
    onMeasurementComplete( dataString ) {
      console.log( "Measurement Complete ");
      let data = JSON.parse( dataString );
      // let whitebalance = this.whitebalance;
      // if ( whitebalance ) {
      //   if ( whitebalance.values.length == data.length && 
      //     whitebalance.values[0].length == data[0].length ) {
      //     console.log( "applying wb");
      //     for (let row = 0; row < data.length; row++ )
      //       for ( let col = 0; col < data[row].length; col++ ) {
      //         data[ row ][ col ] /= whitebalance.values[ row ][ col ];
      //       }
      //   }
      // }
      this.values = data;
      //this.values = [[41,4,5,7,0,3,5,3,3,85],[121,595,252,76,135,139,481,1323,1612,1540],[111,646,592,725,77,1177,1138,853,422,1284],[48,23,344,2669,47,767,110,102,71,801],[3,2,3,6,3,115,56,5,3,32],[121,169,173,601,217,1831,2662,2491,1142,3102],[2,1,4,2,4,28,114,12,3,49],[24,14,32,22,112,116,1307,700,27,993],[32,19,34,38,140,32,639,2216,88,1583],[125,198,199,180,2365,143,154,262,178,5989],[58,106,89,78,1001,66,77,93,66,2153],[29,32,26,48,3931,22,73,42,39,787]];
    },
    async fakeMeaseurement() {
      let dimensions = { rows: 12, cols: 12 };
      let fake_data = Faker.generateRandomData( dimensions.cols, dimensions.rows );
      this.onMeasurementComplete( JSON.stringify( fake_data ) );
    }
  }
}
</script>

<style>

#MeasurementValuesDisplayCard {
  width: 80vw;
  margin-left: auto;
  margin-right: auto;
}

</style>