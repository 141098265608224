<template>
    <div>
        <p>
            Devicename: {{ deviceInfo.name }}
        </p>
        <p>
            Firmware: {{ deviceInfo.firmwareVersion }}
        </p>
        <p>
            Hardware: {{ deviceInfo.hardwareVersion }}
        </p>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters({
            connected: 'scancorder/isConnected',
            deviceInfo: 'scancorder/deviceInfo',
        } )
    },
}
</script>

<style>

</style>