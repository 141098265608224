<template>
    <v-dialog
        v-model="dialog"
        persistent
        scrollable
        id="updateDialog"
        >
        <v-card class="mt-4 elevation-12">
            <v-toolbar dark color="primary">
                Update
            </v-toolbar>
            <v-card-text 
                style="max-height: 50vh;overflow-y: scroll;"
                >
                <div class="info mb-2">
                    <p>
                        It is recommended to connect the Scancorder Device to a power source, while updating the firmware.
                        During the update the status led will continuously flash yellow.
                        Once the update is complete, the device will reboot automatically.
                        The Cicada Application will restart 5 seconds after the update was performed successfully.
                    </p>
                </div>
                <div class="message">
                    {{ message }}
                </div>
                <v-radio-group 
                    v-if="fwsAvailable && fwsAvailable.length > 0"
                    v-model="fwSelected"
                    >
                    <template v-slot:label>
                        <div>Firmwares available:</div>
                    </template>
                    <v-radio
                        v-for="fw in fwsAvailable"
                        :key="fw"
                        :label="fw.version"
                        :value="fw.version"
                    ></v-radio>
                </v-radio-group>
                <div
                    v-else
                    >
                    No firmware update available.
                </div>
                
                <v-progress-linear
                    striped
                    v-if="updateInProgress"
                    v-model="uploadProgress"
                    color="primary"
                    height="10"
                    />
                <div
                    v-if="updateInProgress"
                    class="text-center"
                    >
                    {{ bytesSent }} / {{ bytesTotal }} bytes
                </div>
            </v-card-text>
            <v-card-actions>
                <v-btn
                    @click="update"
                    :disabled="( updateInProgress || !updatePossible || !fwSelected )"
                    >
                    Flash now!
                </v-btn>
                <v-btn
                    @click="$emit('cancel')"
                    :disabled="updateInProgress"
                    >
                    Cancel
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

import ScancorderConfig from '@/config/Scancorder';

import MD5 from 'crypto-js/md5'
import CryptoJS from 'crypto-js'

export default {
    data () {
        return {
            dialog: false,
            updateFile: [],
            updateInProgress: false,
            updatePossible: true,
            message: '',
            fwsAvailable: [],
            fwSelected: '',
            bytesSent: 0,
            bytesTotal: 0,
        }
    },
    props: [
        'show',
    ],
    emits: [
        'finish',
        'cancel',
        'error'
    ],
    watch: {
        show( newValue ) {
            this.dialog = newValue;
        },
    },
    computed: {
        uploadProgress() {
            let progress = (this.bytesTotal > 0) ? 100 * this.bytesSent / this.bytesTotal : 0; 
            return progress; 
        }
    },
    created() {
        // Fetch available update files from server
        this.axios.get( 'fw.json' ).then( ( response ) => {
            
            console.log( response );
            if ( response.status != 200 ) {
                this.message = "Can not load firmware from server.";
                return;
            }
            this.fwsAvailable = response.data;
        } )
    },
    methods: {
        fakeUpdate() {
            this.updateInProgress = true;
            setTimeout( () => {
                this.updateInProgress = false;
                this.$emit('finish');
            }, 3000 )
        },
        async update() {
            this.updateInProgress = true;
            this.bytesTotal = 0;
            this.bytesSent = 0;
            console.log( "Starting update to firmware ", this.fwSelected );
            let fwInfo = this.fwsAvailable.find( fw => fw.version === this.fwSelected )
            if ( ! fwInfo ) {
                this.$emit( 'error', 'Can not find selected firmware.' );
                return;
            }

            // Download firmware
            const fwDownloadUrlBase = '/fw/';
            let response = {};
            try {
                response = await this.axios.get( fwDownloadUrlBase + fwInfo.file, {
                    responseType: 'arraybuffer',
                    headers: {
                        'Content-Type': 'application/x-binary',
                    }
                }); 
            } catch( error ) {
                this.$emit( 'error', 'Can not download firmware file.' );
                this.updateInProgress = false;
                return;
            }
    
            
            console.dir( response );
            let firmwareBuffer = response.data;
    
            if ( firmwareBuffer.byteLength != fwInfo.size ) {
                this.$emit( 'error', 'Error during file download: Size missmatch' );
                this.updateInProgress = false;
                return;
            }
            let hash = MD5( firmwareBuffer ).toString( CryptoJS.enc.Hex );
            console.log( hash );
            
            let firmwareInfo = {
                version: {
                    minor: fwInfo.version.split('.' )[1],
                    major: fwInfo.version.split('.' )[0],
                },
                size: fwInfo.size,
                md5: fwInfo.md5,
            };
            await this.uploadFirmwareInfoToDevice( firmwareInfo );
            if ( await this.uploadFirmwareToDevice( firmwareBuffer ) ) {
                this.updateInProgress = false;
                this.$emit( 'finish' );
                return;
            }
            this.updateInProgress = false;
            this.$emit( 'error', 'Error during firmware upload.' );
        },
        async uploadFirmwareInfoToDevice( fwInfo ) {
            console.dir( fwInfo );
            
            let infoCharacteristics = await this.$store.dispatch('scancorder/queryCharacteristic', {
                serviceUuid: ScancorderConfig.uuids.services.otaProgrammer.uuid,
                characteristicUuid: ScancorderConfig.uuids.services.otaProgrammer.characteristics.firmwareInfo,
            });
            console.log( infoCharacteristics );
            let encoder = new TextEncoder();
            let data = encoder.encode( JSON.stringify( fwInfo ) );
            await infoCharacteristics.writeValueWithoutResponse( data.buffer );
        },
        async uploadFirmwareToDevice( buffer ) {
            this.bytesTotal = buffer.byteLength;
            
            let updateCharacteristic = await this.$store.dispatch('scancorder/queryCharacteristic', {
                serviceUuid: ScancorderConfig.uuids.services.otaProgrammer.uuid,
                characteristicUuid: ScancorderConfig.uuids.services.otaProgrammer.characteristics.firmwareUpload,
            });
            let packetSize = 508;
            while ( this.bytesSent < buffer.byteLength ) {
                let packet = buffer.slice( this.bytesSent, this.bytesSent + packetSize );
                try {
                    await updateCharacteristic.writeValueWithResponse( packet );
                } catch ( error ) {
                    if ( (this.bytesSent + packet.byteLength) === buffer.byteLength ) {
                        // In his special case the very last package was sent.
                        // The GATT communication fails on this, because the
                        // device immediately applies the backup and reboots,
                        // when all data is received. So this is an very expected
                        // error.
                        return true;
                    }
                    return false;
                }
                this.bytesSent += packet.byteLength;
            }
            return true;
        }
    }
}
</script>

<style>
.v-dialog .v-overlay__content {
    max-width: 70vw !important;
}
</style>