<template>
    <div id="MeasurementDataChart">
        <BarChart
            id="barChart"
            :chartData="chartData"
            :options="{
                scales: {
                    y: {
                        title: {
                            text: 'absolute value',
                            display: true, 
                        },
                    },
                    x: {
                        title: {
                            text: 'channel wavelength [nm]',
                            display: true, 
                        },
                    },
                },
                plugins: {
                    legend: {
                        display: false,
                    }
                }
            }"
            />
    </div>
</template>

<script>
import { BarChart } from 'vue-chart-3';
import { Chart, registerables } from "chart.js";

Chart.register(...registerables);
Chart.defaults.backgroundColor = '#71814E';
export default {
    name: "MeasurementDataChart",
    props: [
        'labels',
        'values'
    ],
    components: {
        BarChart,
    },
    computed: {
        chartData() {
            console.log( "computing chartData" );
            let chartData = {
                labels: this.labels,
                datasets: [],
            };
            
            for( let illuminationData of this.values ) {
                let goodColor = '#71814E';
                let badColor = '#821a1a'
                let threshold = 65000;
                let colors = [];
                for ( let value of illuminationData ) {
                    colors.push( value >= threshold ? badColor : goodColor  )
                }
                chartData.datasets.push( { 
                    data: illuminationData, 
                    backgroundColor: colors,
                } );
            }
            console.log( chartData );
            return chartData;
        }
    }
}
</script>

<style>
#barChart {
    height: 30vh;
    height: calc( 100vh - 240px );
}

</style>