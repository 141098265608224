import { openDB } from 'idb'
const mdb = {
    db: null,
    async setup() {
        this.db = await openDB('Measurements', 1, {
            upgrade(db) {
                // Create a store of objects
                const store = db.createObjectStore('measurements', {
                    // The 'id' property of the object will be the key.
                    keyPath: 'id',
                    // If it isn't explicitly set, create a value by auto incrementing.
                    autoIncrement: true,
                });
                store.createIndex('timestamp', 'timestamp');
                store.createIndex('device', 'device');
                store.createIndex('values', 'values');
            },
        });
    }
}


export default mdb;