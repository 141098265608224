<template>
  
  <div class="slider-container">
    <!-- <div class="text-caption">
        {{ name }} {{ showGainDetails ? Math.pow(2, value - 1 ) : '' }}
    </div> -->
    <div
      style="display:flex; align-items:baseline">
      <v-slider
          v-model="value"
          color="primary"
          step="1"
          :min="min"
          :max="max"
          thumb-label
          :messages="name + (showGainDetails ? ' ' + Math.pow(2, value - 1 ) : '' )"
          >
      </v-slider>
      <input
        v-model="value"
        type="number"
        style="margin-left:0.5em; display:block; width: 70px"
      />
    </div>
  </div>
</template>

<script>

import BleUtils from '@/utils/BleUtils';
export default {

  props: {
    name: String,
    characteristicUuid: String,
    serviceUuid: String,
    type: String,
    min: Number,
    max: Number,
    showGainDetails: Boolean,
  },
  data() {
    return {
      value: 0,
      characteristicHandler: null,
      mountedComplete: false,
    }
  },
  mounted() {
    this.readValueFromDevice().then( () => {
      this.mountedComplete = true;
    } );
    this.value = this.min;
  },
  beforeUnmount() {
    this.writeValueToDevice();
  },
  methods: {
    async readValueFromDevice() {
      let bleValue = await this.$store.dispatch('scancorder/queryValue', {
          serviceUuid: this.serviceUuid,
          characteristicUuid: this.characteristicUuid,
      });
      if ( bleValue.byteLength == 0 )
        return;
      
      if ( this.type == "uint8")
        this.value = BleUtils.bleValueToUInt8( bleValue );
      else if ( this.type == "uint16")
        this.value = BleUtils.bleValueToInt16( bleValue );
      else if ( this.type == "uint32") {
        this.value = BleUtils.bleValueToInt32( bleValue );
      }
    },
    async writeValueToDevice() {
      let characteristic = await this.$store.dispatch('scancorder/queryCharacteristic', {
          serviceUuid: this.serviceUuid,
          characteristicUuid: this.characteristicUuid,
      });
      if ( !characteristic ) 
        return;
      let buffer = null;
      if ( this.type == "uint8")
         buffer = new Uint8Array( [this.value] ).buffer;
      else if ( this.type == "uint16")
         buffer = new Uint16Array( [this.value] ).buffer;
      else if ( this.type == "uint32")
         buffer = new Uint32Array( [this.value] ).buffer;

      //console.log( this.name, " writing buffer ", buffer ); 
      await BleUtils.retriableAction( () => { return characteristic.writeValueWithResponse( buffer ) } );
    }
  }
}
</script>

<style scoped>
.v-input__append {
  padding: 0px;
  display: block;
  align-items: center;
}

</style>